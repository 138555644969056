import React from "react";
import './Footer.css';

const Footer = ()=>{
    return(
    <>
     <footer id="site-footer">
        <div className="contact">
            <span> Contact me at </span>
             <a className="highlighted-text phone" href="tel:973-294-6518">(973)294-6518</a>
               <span> or </span>  
             <a href="mailto:ajsmobilewrench@gmail.com"><span className="highlighted-text">ajsmobilewrench@gmail.com</span>,</a>
            <br></br>
            <span>for more information or a quote.</span>
        </div>
        <div className="business-footer">
            <p>
                <span className="highlighted-text">A.J's Mobile Wrench</span>
                <br className="footer-slogan"></br>
                <span>  - We Come To You.</span> 
            </p>
        </div>
    </footer>
    </>
    )

}

export default Footer;