import {React} from "react";
import Footer from "../../Components/Footer/Footer";
import logo from '../../assets/images/logo.png';
import './HomePage.css';
import Navbar from "../../Components/Navbar/Navbar";


const HomePage = () => {

    return (
        <>
            <Navbar></Navbar>
            <section id="header">
                <div className="site-logo-container">
                    <img className="site-logo" src={logo} alt="" />
                </div>

                <section className="services">
                    <h1 className="services-header">Automotive Services</h1>
                    <ul className="services-list">
                        <li>Brakes</li>
                        <li>Charging Systems</li>
                        <li>Diagnostics</li>
                        <li>Exhaust</li>
                        <li>Suspension</li>
                        <li>Welding</li>
                    </ul>
                </section>

            </section>

            <section id="site-body">
                <section id="breakdown-section">
                    <h2 className="body-header">Emergency Breakdowns Welcome</h2>
                    <p className="breakdown-text">
                        If you break down or need a jump start I will come to you to make the repairs necessary to get you back on the road.
                        Again a cheaper alternative to getting the vehicle towed to a repair facility to make the repairs,
                        with Aj's Mobile wrench we cut out the middle man and get the vehicle repaired ASAP and get you up and running in no time.
                    </p>
                </section>

                <section id="inspection-section">
                    <h2 className="body-header">Used Vehicle Inspections</h2>
                    <p className="inspection-text">
                        Have peace of mind knowing you are purchasing a quality used vehicle. A.J's Mobile Wrench will come to you
                        and inspect a used vehicle before or after it's purchase. Call for more information.
                    </p>
                </section>

                <section id="welding-section">
                    <h2 className="body-header">Welding Services</h2>
                    <p className="welding-text">
                        We offer flux core welding for both automotive and general purposes. Call or text for a quote.
                    </p>
                </section>

                <section id="disclaimer-section">
                    <h3 className="disclaimer-header">Disclaimer</h3>
                    <p className="disclaimer-text">We do not offer towing but can make refurals. We do not replace tires on site. Not all services can be completed with a mobile mechanic.</p>
                </section>

            </section>

            <Footer></Footer>

        </>);
}

export default HomePage;