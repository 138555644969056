import React, { useEffect, useState } from "react";
import './Navbar.css';
import logo from '../../assets/images/logo.png';

function Navbar() {
    const [navOpen, setNavOpen] = useState(false);

    //once per load
    useEffect(() => {

        if (navOpen) {
            document.querySelector('#nav-shelf').style.maxHeight = '200px';
            document.querySelector('#nav-shelf').style.paddingTop = '20px';
            document.querySelector('#nav-shelf').style.paddingBottom = '20px';
        } else {
            document.querySelector('#nav-shelf').style.maxHeight = '0px';
            document.querySelector('#nav-shelf').style.paddingTop = '0px';
            document.querySelector('#nav-shelf').style.paddingBottom = '0px';
        }

    }, [navOpen]);

    /*
    function toggleTheme() {
        if (theme == 'light') {
            setTheme('dark');
        } else {
            setTheme('light');
        }
    }
    */

    function toggleNav() {
        let toggle = document.querySelector('#nav-toggle');
        toggle.classList.toggle('open');
        setNavOpen(!navOpen);
    }


    function removeAllActive(){
        document.querySelectorAll('.nav-link').forEach((link)=>{
            link.classList.remove('active');
        })
    }

    function toServices(){
        removeAllActive();
        let services = document.querySelector('#header');
        services.scrollIntoView({behavior : 'smooth'});

        //set services active
        document.querySelector('#services-link').classList.add('active');

    }

    function toContact(){
        removeAllActive();
        let footer = document.querySelector('#site-footer');
        footer.scrollIntoView({behavior : 'smooth'});

        //set contact active
        document.querySelector('#contact-link').classList.add('active');
    }

    return (
        <nav id="nav" className="navbar">
            <section className="nav-container">
                <section className="nav-left">

                    {/*
                    <div className="logo-container">
                        <img className="logo-image" src={logo} alt="sean logo" />
                    </div>
                    */}
                    

                    <section className="nav-title-section">
                        <p className="title-name">A.J's Mobile Wrench</p>
                    </section>
                </section>

                <section className="links-section">
                    {/*
                    <div className="theme-button" onClick={toggleTheme}>
                        {theme == 'light' ?
                            <img className="theme-image" src={moon} alt="Dark Theme" />
                            :
                            <img className="theme-image" src={sun} alt="Light Theme" />
                        }
                    </div>
                    */}
                    <a id="services-link" className="nav-link active" onClick={toServices}>Services</a>
                    <a id="contact-link" className="nav-link" onClick={toContact}>Contact Me</a>
                </section>

                <section className="responsive-links">
                    <button id="nav-toggle" className="nav-toggle" onClick={toggleNav}>
                        <div className="nav-toggle-brgr">
                        </div>
                    </button>
                </section>
            </section>

            <section id="nav-shelf" className="nav-shelf">
                <a id="services-link" className="nav-link active" onClick={toServices}>Services</a>
                <a id="contact-link" className="nav-link" onClick={toContact}>Contact Me</a>
                {/*
                <div className="theme-button" onClick={toggleTheme}>
                    {theme == 'light' ?
                        <img className="theme-image" src={moon} alt="Dark Theme" />
                        :
                        <img className="theme-image" src={sun} alt="Light Theme" />
                    }
                </div>
                */}

            </section>
        </nav>
    )

}

export default Navbar;