
import HomePage from "./Pages/HomePage/HomePage";
import './App.css';

function App() {
  return (
  <>
  <HomePage></HomePage>
  </>);
}

export default App;
